<template>
  <div v-if="this.$route.name == 'Admin_Analytics'">
    <Navigation
      v-bind:Breadcrumb="[
        { name: 'Home', route: 'Home' },
        { name: 'Staff', route: 'Admin' },
        { name: 'Analytics', route: 'Admin_Analytics' },
      ]"
    />
    <div class="placeholder">
      <div class="row">
        <div class="col-4">
          <div class="card">
            <div class="text">Total Users</div>
            <div class="infoBlock">{{ basicAnalytics.totalUsers }}</div>
          </div>
        </div>
        <div class="col-4">
          <div class="card">
            <div class="text">Total Businesses</div>
            <div class="infoBlock">{{ basicAnalytics.totalBusinesses }}</div>
          </div>
        </div>
        <div class="col-4">
          <div class="card">
            <div class="text">Total Tests</div>
            <div class="infoBlock">{{ basicAnalytics.totalTests }}</div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-3">
          <div class="card">
            <div class="text">Change Date Range</div>
            <form class="form" v-on:submit.prevent="getAnalytics">
              <label for="form.date_start">From Date</label>
              <input
                type="date"
                class="input"
                v-model="form.date_start"
                required
              />
              <label for="form.date_end">Too Date</label>
              <input
                type="date"
                class="input"
                v-model="form.date_end"
                required
              />
              <input
                type="submit"
                class="btn btn-green"
                value="Fetch Analytics"
              />
            </form>
          </div>
          <br>
          <div class="card">
            <div class="text">Test Data Download (for Analytics)</div>
          <br>
            <form class="form" v-on:submit.prevent="getReport">
              <input
                type="submit"
                class="btn btn-green"
                value="Download Historic Test Data"
              />
            </form>
          </div>
        </div>
        <div class="col-6">
          <div class="card">
            <div class="text">Total Tests by Weeks</div>
            <Line
              ref="piechart_totals"
              :chart-options="lineChartOptions"
              :chart-data="lineChartData"
              :chart-id="chartId"
              :dataset-id-key="datasetIdKey"
              :plugins="plugins"
              :css-classes="cssClasses"
              :styles="styles"
              :width="width"
              :height="height"
              :arc="arc"
            />
          </div>
        </div>
        <div class="col-3">
          <div class="card">
            <div class="text">Total Tests by Status</div>
            <Doughnut
              ref="piechart_totals"
              :chart-options="pieChartOptions"
              :chart-data="pieChartData"
              :chart-id="chartId"
              :dataset-id-key="datasetIdKey"
              :plugins="plugins"
              :css-classes="cssClasses"
              :styles="styles"
              :width="width"
              :height="height"
              :arc="arc"
            />
          </div>
        </div>
        <div class="col-6">
          <div class="card">
            <div class="text">Total Tests by Businesses</div>
            <Bar
              ref="piechart_totals"
              :chart-options="barChartOptions"
              :chart-data="barChartData"
              :chart-id="chartId"
              :dataset-id-key="datasetIdKey"
              :plugins="plugins"
              :css-classes="cssClasses"
              :styles="styles"
              :width="width"
              :height="height"
              :arc="arc"
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <table>
            <thead>
              <tr>
                <td>Business</td>
                <td>Organisation</td>
                <td>Total Tests Remaining</td>
                <td>Total Tests Attempted</td>
                <td>Total Tests Confirmed</td>
                <td>Total Tests Failed</td>
                <td>Date of Last Test</td>
                <td>Days Since Last Test</td>
              </tr>
            </thead>
            <tbody>
              <tr v-for="row in detailedReport" :key="row.businessname">
                <td>{{ row.businessname }}</td>
                <td>{{ row.organisationname || "Independant" }}</td>
                <td>{{ row.credits }}</td>
                <td>{{ row.tests_used }}</td>
                <td>{{ row.tests_used - row.tests_failed }}</td>
                <td>{{ row.tests_failed }}</td>
                <td>{{ TimeParse(row.last_testdate) }}</td>
                <td>{{ row.days && row.days?.days ? row.days.days : null }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <router-view />
  </div>
</template>

<script>
import { useStore } from "vuex";
document.title = "MicronAgritech | Admin";

import { Line, Doughnut, Bar } from "vue-chartjs";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  ArcElement,
  PointElement,
  LineElement,
} from "chart.js";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  ArcElement,
  PointElement,
  LineElement
);

import Navigation from "../components/Navigation.vue";
export default {
  name: "Admin",
  setup() {
    const store = useStore();

    return { store };
  },
  props: {
    chartId: {
      type: String,
      default: "pie-chart",
    },
    datasetIdKey: {
      type: String,
      default: "label",
    },
    width: {
      type: Number,
      default: 400,
    },
    height: {
      type: Number,
      default: 400,
    },
    cssClasses: {
      default: "",
      type: String,
    },
    styles: {
      type: Object,
      default: () => {},
    },
    plugins: {
      type: Object,
      default: () => {},
    },
    arc: {
      type: String,
      default: "",
    },
  },
  components: {
    Navigation,
    Doughnut,
    Line,
    Bar,
  },
  computed: {
    FullName() {
      return this.store.state["User"].info.name;
    },
    isStaff() {
      return this.store.state["User"].info.is_staff;
    },
    avatar() {
      return this.store.state["User"].info.avatar;
    },
  },
  methods: {
    SetTotalTestsChart() {
      this.store
        .dispatch("Admin/GetTotalTestsAnalytics", {
          ...this.form,
        })
        .then(async (result) => {
          var chartTotals = { process: 0, negative: 0, positive: 0, failed: 0 };
          result.data.map((row) => {
            console.log(row);
            if (row.status == "process") {
              chartTotals.process = row.totals;
            } else if (row.status == "green") {
              chartTotals.negative = row.totals;
            } else if (row.status == "red") {
              chartTotals.positive = row.totals;
            } else if (row.status == "failed") {
              chartTotals.failed = row.totals;
            }
          });

          this.pieChartData.datasets[0].data = [
            parseInt(chartTotals.process) || 0,
            parseInt(chartTotals.negative) || 0,
            parseInt(chartTotals.positive) || 0,
            parseInt(chartTotals.failed) || 0,
          ];
        });
    },
    SetTotalTestsByWeekChart() {
      this.store
        .dispatch("Admin/GetTotalTestsByWeekAnalytics", {
          ...this.form,
        })
        .then(async (result) => {
          this.lineChartData.datasets = [];
          this.lineChartData.labels = [];
          var chartTotals = [];
          result.data.map((row) => {
            !this.lineChartData.labels.includes(row.week.substring(0, 10))
              ? this.lineChartData.labels.push(row.week.substring(0, 10))
              : null;
            chartTotals.push(row.count);
          });

          this.lineChartData.datasets.push({
            label: "Total Tests",
            data: chartTotals,
            borderColor: ["rgba(75, 192, 192, 0.7)"],
            backgroundColor: ["rgba(75, 192, 192, 1)"],
            pointStyle: "circle",
            pointRadius: 10,
            pointHoverRadius: 15,
          });
        });
    },
    SetTotalTestsByBusinessChart() {
      this.store
        .dispatch("Admin/GetTotalTestsByBusinessAnalytics", {
          ...this.form,
        })
        .then(async (result) => {
          this.barChartData.datasets = [];
          this.barChartData.labels = [];
          var chartTotals = [];
          result.data.map((row) => {
            !this.barChartData.labels.includes(row.name)
              ? this.barChartData.labels.push(row.name)
              : null;
            chartTotals.push(row.count);
          });

          this.barChartData.datasets.push({
            label: "Total Tests",
            data: chartTotals,
            borderColor: ["rgba(75, 192, 192, 0.7)"],
            backgroundColor: ["rgba(75, 912, 192, 1)"],
            pointStyle: "circle",
            pointRadius: 10,
            pointHoverRadius: 15,
          });
        });
    },
    setupDates() {
      const date1 = new Date();
      const date2 = new Date(date1);

      date2.setDate(date2.getDate() - 7);

      var dateend = `${date1.getFullYear()}-${(
        "0" +
        (date1.getMonth() + 1)
      ).slice(-2)}-${("0" + date1.getDate()).slice(-2)}`;

      var datestart = `${date2.getFullYear()}-${(
        "0" +
        (date2.getMonth() + 1)
      ).slice(-2)}-${("0" + date2.getDate()).slice(-2)}`;

      this.form.date_start = datestart;
      this.form.date_end = dateend;
    },
    getAnalytics() {
      this.SetTotalTestsChart();
      this.SetTotalTestsByWeekChart();
      this.SetTotalTestsByBusinessChart();
      this.SetDetailedReportTable();
    },
    async getReport(){
      
      this.store
        .dispatch("Admin/GetBulkResult")
        .then((resultbulk)=>{
          //console.log("Hello from here",(resultbulk));
          var arrays = [];
          resultbulk.forEach((rows) =>{
            // console.log("These are the rows - ",typeof(rows));

            arrays.push({
                  "id":rows.id,
                  "cow_id": rows.cow_id,
                  "status": rows.status,
                  "created_at":rows.created_at,
                  "user_id": rows.user_id,
                  "bucket_file":rows.bucket_file,
                  "original_file":rows.original_file,
                  "request_id": rows.request_id,
                  "counter_for_user": rows.counter_for_user,
                  "farmer_name":rows.farmer_name,
                  "business_id":rows.business_id,
                  "animaltype":rows.animaltype,
                  "sampletype":rows.sampletype,
                  "mltime":rows.mltime,
                  "failreason":rows.failreason,
                  "client_id":rows.client_id,
                  "reviewed":rows.reviewed,
                  "reviewed_by":rows.reviewed_by,
                  "tester_name":rows.tester_name,
                  "reviewer_obs":rows.reviewer_obs,
                  "test_type":rows.test_type,
                  "uuid":rows.uuid,
                  "device_model":rows.device_model,
                  "business":rows.business,
                  "address":rows.address,
                  "postcode":rows.postcode,
                  "organisation":rows.organisation,
                  "username":rows.username,
                  "coccidia_count":rows.coccidia_count,
                  "nematodirus_count":rows.nematodirus_count,
                  "strongyle_count": rows.strongyle_count,
                  "strongyloides_count":rows.strongyloides_count,
                  "trichuris_count":rows.trichuris_count,
                  "moniezia_count":rows.moniezia_count,
                  "ascaridis_count":rows.ascaridis_count,
                  "anoplocephala_count":rows.anoplocephala_count,
                  "Rumen_Fluke_count": rows.rumen_fluke_count,
                  "Liver_Fluke_count":rows.liver_fluke_count

                });
          });
            const items = arrays;
            console.log("Here is the result",items);
            // const replacer = (key,value) => (value === null ? "": value);
            const header = Object.keys(items[0]);
            const csv = [
                header.join(","), // header row first
                ...items.map((row) =>
                  header
                    .map((fieldName) =>
                      JSON.stringify(row[fieldName])//, replacer).replace(/\\"/g, '""')
                    )
                    .join(",")
                ),
              ].join("\r\n");
            const data = "data:text/csv;charset=utf-8," + csv;
            const link = document.createElement("a");
            link.setAttribute("href", data);
            link.setAttribute("download", "export.csv");
            link.click();
          console.log("Here is the result",csv.length);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    ,
    getTotals() {
      this.store
        .dispatch("Admin/GetTotalsBlocks")
        .then((result) => {
          this.basicAnalytics.totalUsers = result.data.totalusers;
          this.basicAnalytics.totalBusinesses = result.data.totalbusinesses;
          this.basicAnalytics.totalTests = result.data.totaltests;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    SetDetailedReportTable() {
      this.store
        .dispatch("Admin/GetDetailedReportAnalytics", {
          ...this.form,
        })
        .then((result) => {
          this.detailedReport = result.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    TimeParse(time) {
      let date = new Date(time);
      let returntime = `${("0" + date.getDate()).slice(-2)}/${(
        "0" +
        (date.getMonth() + 1)
      ).slice(-2)}/${date.getFullYear()} ${("0" + date.getHours()).slice(
        -2
      )}:${("0" + date.getMinutes()).slice(-2)}`;

      return returntime;
    },
  },
  data() {
    return {
      basicAnalytics: {
        totalUsers: 0,
        totalBusinesses: 0,
        totalTests: 0,
      },
      form: {
        date_start: "",
        date_end: "",
      },
      pieChartData: {
        labels: ["Processing", "Negative", "Positive", "Failed"],
        datasets: [
          {
            label: "Total Tests",
            data: [],
            backgroundColor: [
              "rgba(75, 192, 192, 0.2)",
              "rgba(54, 162, 235, 0.2)",
              "rgba(255, 99, 132, 0.2)",
              "rgba(255, 206, 86, 0.2)",
            ],
            borderColor: [
              "rgba(75, 192, 192, 1)",
              "rgba(54, 162, 235, 1)",
              "rgba(255, 99, 132, 1)",
              "rgba(255, 206, 86, 1)",
            ],
            borderWidth: 1,
          },
        ],
      },
      pieChartOptions: {
        responsive: true,
      },
      barChartData: {
        labels: [],
        datasets: [],
      },
      barChartOptions: {
        responsive: true,
      },
      lineChartData: {
        labels: [],
        datasets: [],
      },
      lineChartOptions: {
        responsive: true,
      },
      detailedReport: [],
    };
  },
  created() {
    this.getTotals();
    this.setupDates();
  },
};
</script>

<style>
.infoBlock {
  font-weight: 800;
  color: black;
  font-family: Arial, Helvetica, sans-serif;
  text-align: center;
  padding: 10px;
  font-size: 35px;
}
</style>
